<template>
    <div id="news_detail" :class="{webp: isSupportWebp}" data-view>
        <Navigation @changeLocale="changeLocale" :locale="locale"/>

        <div class="main">
            <Detail :post="post"  :locale="locale"/>
            <RelatedNews :related_posts="related_posts"/>
        </div>

        <Footer />
    </div>
</template>

<script>
    const Navigation = () => import("./components/news/Navigation");
    const Detail  = () => import("./components/news-detail/Detail");
    import {Post} from "./mixins/post";
    import lazyLoadComponent from "./utils/lazy-load-component";
    import SkeletonBox from "./components/SkeletonBox";
    import 'lazysizes';

    const WebFont = require('webfontloader')

    export default {
        name: 'NewsDetail',
        mixins: [Post],
        components: {
            Navigation,
            Detail,
            RelatedNews: lazyLoadComponent({
                componentFactory: () => import('./components/news-detail/RelatedNews'),
                loading: SkeletonBox
            }),
            Footer: lazyLoadComponent({
                componentFactory: () => import('./components/news/Footer'),
                loading: SkeletonBox
            }),
        },
        data() {
            return {
                num: 0,
                numberposts: 6,
                locale: localStorage.locale || 'vi',
            }
        },
        mounted() {
            document.onreadystatechange = () => {
                if (document.readyState === "complete") {
                    WebFont.load({
                        google: {
                            families: ['Open Sans:400,700&display=swap']
                        }
                    })
                    document.querySelector('html').classList.remove('loading')
                }
            }
        },
        computed: {
            switchedLocale() {
                return this.locale === 'vi' ? 'en' : 'vi'
            }
        },
        methods: {
            getNum(data) {
                this.num = parseInt(data)
            },
            navScroll: function () {
                let el = document.querySelectorAll('[data-scroll]');
                let totalSelector = el.length;

                for (let i = 0; i < totalSelector; i++) {
                    let posTop = el[i].getBoundingClientRect().top,
                        posBottom = el[i].getBoundingClientRect().bottom;

                    if (posTop <= 300 && posBottom >= 300) {
                        document.querySelector(`[data-anchor="${i}"]`).classList.add('active')
                    } else {
                        document.querySelector(`[data-anchor="${i}"]`).classList.remove('active')
                    }
                }
            },
            changeLocale(locale) {
                if(this.post.translations[this.switchedLocale]) {
                    this.fetch_post(this.post.translations[this.switchedLocale])
                } else {
                    window.location.href = "/news/"
                }
                localStorage.locale = this.locale
                this.$i18n.locale = locale
                this.locale = locale
            }
        }
    }
</script>

<style lang="scss">
    @import './assets/scss/all.scss';

    .main {
        padding-top: 8.5rem;
        @include media(set-range, 1199, 992) {
            padding-top: 11rem;
        }
        @include media(set-min-w, 1200) {
            padding-top: 14.7rem;
            padding-right: 17rem;
            padding-left: 17rem;
        }
        @include media(set-min-w, 1600) {
            padding-right: 26rem;
            padding-left: 26rem;
        }

        .container {
            width: 100%;
            max-width: 134rem;
            padding: 0 10px;
            margin: 0 auto;
        }
    }
</style>